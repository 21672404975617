<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加角色</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :paging="table.paging" pagination :method="table.get" :loading="table.loading">
          <en-table-column label="操作">
            <template #default="{ row }: { row: EnocloudAdminDefinitions['RoleDto'] }">
              <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
            </template>
          </en-table-column>
          <en-table-column label="角色名称">
            <template #default="{ row }: { row: EnocloudAdminDefinitions['RoleDto'] }">
              <button-ajax text :method="table.name.click" :params="row">{{ row.name }}</button-ajax>
            </template>
          </en-table-column>
          <en-table-column label="通知角色" prop="category.message"></en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑角色' : '添加角色'" direction="btt" size="80%">
    <en-form
      :model="detail.form.data"
      :loading="detail.form.loading"
      :rules="detail.form.rules"
      :ref="setRef('detailForm')"
      class="grid grid-cols-5 gap-x-6"
    >
      <en-form-item label="角色名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="通知角色" prop="category.code">
        <select-maintain
          v-model="detail.form.data.category"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['ROLECTY'])
          }"
          :props="{ label: 'message', value: '' }"
          filterable
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <flex-box>
      <template #default="{ height }">
        <en-tabs v-model="detail.tabs.active">
          <en-tab-pane v-for="tab of detail.tree.nodes" :label="tab.data.message" :name="tab.data.code">
            <en-scrollbar :height="height - 55">
              <en-collapse>
                <en-collapse-item v-for="item of tab.children" :title="item.data.message">
                  <template
                    v-if="
                      [
                        'COMMON_MANAGEMENT',
                        'MANAGE_ALL',
                        'SPRAY_TENANT_FINANCE_MANAGEMENT',
                        'SPRAY_TENANT_REPORT_MANAGEMENT',
                        'OA_CUSTOMER_OPERATOR',
                        'OA_CUSTOMER_MANAGER'
                      ].includes(item.data.code)
                    "
                  >
                    <en-checkbox :model-value="item.isChecked" :label="item.data.code" @change="detail.tree.checked(item, $event as boolean)">
                      <span class="text-lg font-bold">{{ item.data.message }}</span>
                    </en-checkbox>
                    <div class="grid grid-cols-8">
                      <en-checkbox
                        v-for="child of item.children"
                        :model-value="child.isChecked"
                        :label="child.data.code"
                        @change="detail.tree.checked(child, $event as boolean)"
                      >
                        <span>{{ child.data.message }}</span>
                      </en-checkbox>
                    </div>
                  </template>

                  <template v-else>
                    <div class="flex flex-col gap-6">
                      <div v-for="child of item.children" class="flex flex-col">
                        <en-checkbox :model-value="child.isChecked" :label="child.data.code" @change="detail.tree.checked(child, $event as boolean)">
                          <span class="text-lg font-bold">{{ child.data.message }}</span>
                        </en-checkbox>

                        <div class="grid grid-cols-8">
                          <en-checkbox
                            v-for="subChild of child.children"
                            :model-value="subChild.isChecked"
                            :label="subChild.data.code"
                            @change="detail.tree.checked(subChild, $event as boolean)"
                          >
                            {{ subChild.data.message }}
                          </en-checkbox>
                        </div>
                      </div>
                    </div>
                  </template>
                </en-collapse-item>
              </en-collapse>
            </en-scrollbar>
          </en-tab-pane>
        </en-tabs>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>

  <en-upload :ref="setRef('uploadRef')" action="/enocloud/admin/user/import" :on-success="upload.success" class="absolute"></en-upload>
</template>

<script lang="ts">
type AccessRightDto = EnocloudCommonDefinitions['AccessRightDto']

class Node<T> {
  readonly level: number

  childrenData: any[]

  children: Node<T>[]

  isChecked: boolean = false

  constructor(
    readonly data: T,
    readonly config = { children: 'children' },
    readonly parent?: Node<T>
  ) {
    const { children } = config

    this.level = parent ? parent.level + 1 : 1

    this.childrenData = (data as any)[children]
    this.children = (this.childrenData ?? []).map((item) => new Node(item, config, this))
  }

  set checked(value: boolean) {
    this.isChecked = value
    this.updateChildrenCheckedStatus(value)
    this.updateParentCheckedStatus()
  }

  updateChildrenCheckedStatus(value: boolean) {
    for (const item of this.children) {
      item.checked = value
    }
  }

  updateParentCheckedStatus() {
    if (!this.parent) return
    let allSiblingsChecked = true
    for (const sibling of this.parent.children) {
      if (!sibling.isChecked) {
        allSiblingsChecked = false
        break
      }
    }
    this.parent.isChecked = allSiblingsChecked
    this.parent.updateParentCheckedStatus()
  }
}

const setCheckedNodes = (treeData: Node<AccessRightDto>[], codes: string[]) => {
  for (const item of treeData) {
    if (codes.includes(item.data.code)) item.checked = true
    setCheckedNodes(item.children, codes)
  }
}

const flatCheckedNodes = (nodes: Node<AccessRightDto>[]) => {
  return nodes.reduce((res, node) => {
    if (node.isChecked) res.push(node.data)
    res = res.concat(flatCheckedNodes(node.children))
    return res
  }, [] as AccessRightDto[])
}

export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.tree.nodes = this.detail.tree.data.map((item) => new Node(item))
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/admin/role',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/admin/role/:roleId'
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudAdminDefinitions['RoleDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          },
          name: {
            async click(row: EnocloudAdminDefinitions['RoleDto']) {
              this.detail.tabs.active = this.detail.tree.data?.[0].code
              this.detail.form.init()
              this.detail.form.data.id = row.id
              this.detail.visible = true
              return this.detail.form.get().then(() => {
                this.detail.tree.nodes = this.detail.tree.data.map((item) => new Node(item))
                setCheckedNodes(
                  this.detail.tree.nodes,
                  this.detail.form.data.accessRights.map((item) => (item as AccessRightDto).code)
                )
              })
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          tabs: {
            active: ''
          },
          form: {
            rules: {
              name: [{ required: true, message: '请填写角色名称' }],
              'category.code': [{ required: true, message: '请选择通知角色' }]
            },

            ajax: {
              get: {
                action: 'GET /enocloud/admin/role/:roleId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/admin/role',
                validate: true,
                params(params) {
                  params.payload = Object.assign({}, this.detail.form.data)
                  params.payload.accessRights = flatCheckedNodes(this.detail.tree.nodes)
                }
              },
              update: {
                action: 'PUT /enocloud/admin/role',
                validate: true,
                params(params) {
                  params.payload = Object.assign({}, this.detail.form.data)
                  params.payload.accessRights = flatCheckedNodes(this.detail.tree.nodes)
                }
              }
            },
            computed: {
              accessRightCodes() {
                return this.detail.form.data.accessRights.map((item) => (item as EnocloudCommonDefinitions['AccessRightDto']).code)
              }
            }
          },
          tree: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/accessright',
                data: 'array',
                loading: true
              }
            },
            nodes: [] as Node<AccessRightDto>[],
            checked(item: Node<AccessRightDto>, value: boolean) {
              item.checked = value
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },
      upload: {
        success() {
          this.table.get()
        }
      }
    }
  },

  mounted() {
    this.table.get()
    this.detail.tree.get().then(() => {
      this.detail.tabs.active = this.detail.tree.data?.[0].code
    })
  }
})
</script>
